import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { setAuthToken, getAuthToken, getAuthUserName, resetAuthToken, hasAuthToken, Logout, activationUser } from './Utils.js';
import Balance from './Balance';
import LoginForm, { LoginFormOpen } from './Form/LoginForm';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../utils/style';
import IcomoonIcon from '../shared/IcomoonIcon';
import StoreContext from '../shared/Context/StoreContext';
import WithdrawForm, { WithdrawFormOpen } from '../Content/Withdraw/Comments/Form.js';

const Wrapper = styled('div')`
  width: 300px;
  margin: 20px auto;
  text-align: center;
  .user-balance, .account-accordion {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  .user-balance {
    position: relative;
    border: 1px solid ${color.darkOrange};
    background: transparent;
    padding: 9.5px 45px 9.5px 90px;
    border-radius: 25px;
    margin-right: 10px;
  }
  .account-accordion {
    position: relative;
    .accordion-title {
      background: linear-gradient(to left, #ff3e41, #ffc000);
      padding: 14px 50px 14px 25px;
      color: ${color.trueBlack};
      font-size: 16px;
      &::after {
        padding: 5px;
        top: 14px;
        right: 20px;
        left: auto;
        border-color: ${color.trueBlack};
      }
    }
    .accordion-content {
      position: absolute;
      top: 104%;
      right: 0;
      z-index: 1;
      overflow: hidden;
      background: ${color.white};
      border: 1px solid ${color.white};
      height: auto;
      padding: 0;
      border-radius: 5px;
      width: 100%;
      li {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        color: ${color.trueBlack};
      }
    }
    .a, a {
      display: block;
      width: 100%;
      padding: 7px 10px;
      border: 1px solid ${color.white};
      border-top-color: #EEE;
      outline: none;
      border-radius: 0;
      color: ${color.trueBlack};
      background: ${color.white};
      font-size: 0.9rem;
      cursor: pointer;
      span {
        color: ${color.trueBlack};
        font-size: 0.9rem;
      }
      &:hover, &:active, &:focus {
        background: ${color.orange};
        text-decoration: none;
        color: ${color.trueBlack};
        border-color: ${color.orange};
      }
    }
    &:hover, &.accordion-open {
      .accordion-title {
        &::after {
          transform: rotate(-135deg);
          top: 19px;
        }
      }
      .accordion-content {
        display: block;
      }
    }
  }
  .coin-balance {
    display: inline-block;
    vertical-align: top;
  }
  .icon-wrap {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    top: 13px;
    left: 8px;
    cursor: pointer;
    transition: 0.3s all;
    width: 18px;
    height: 18px;
    &.update {
      transform: rotate(180deg);
    }
  }
  .coin-wrap { 
    position: absolute;
    left: 30px;
    top: 7px;
  }
  .coin-balance { line-height: 21px; }
  .user-name {
    font-weight: bold;
    display: block;
    padding: 7px 10px;
  }
  .button {
    margin: 5px 10px;
  }
  .transparent-button-orange {
    svg {
      margin-right: 7px;
    }
  }
  .logout-button {
    svg {
      margin-left: 7px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin: 0 0 10px;
  }
`;

const LogoutButton = ({ inListing }) => {
  const value = useContext(StoreContext);
  const setUsernameCallback = value.setUsernameCallback;
  return(
    <button className={(!inListing) ? "button gradient-button logout-button" : "a"} onClick={(e)=>Logout(e, setUsernameCallback)}>
      <span>ลงชื่อออก</span>
      {!inListing &&
      <IcomoonIcon icon="link" color={color.black} size={16}/>
      }
    </button>) };
const LoginButton = () => {
  return (
    <button className="button transparent-button" onClick={(e)=>LoginFormOpen(e, 'login-popup-window')}>
      <span>เข้าสู่ระบบ</span>
    </button>)
};

const LoginRegisterButtons  = ({ hideRegButton }) => {
  return(<React.Fragment>
    <LoginButton/>
    {!hideRegButton &&
    <a className="button transparent-button register-button" href="/register/?register_type=onsitegames">
      <span>สมัครสมาชิก</span>
    </a>
    }
  </React.Fragment>) };

const Auth = ({ hideRegButton }) => {
  const value = useContext(StoreContext);
  const username = value.username;
  return (
    username ?
      <Wrapper className="auth-wrapper">
        <Balance/>
        <div className="accordion account-accordion">
          <div className="accordion-title">บัญชีของฉัน</div>
          <div className="accordion-content">
            <ul>
              <li><span className="user-name">{username}</span></li>
              <li>
                <button className="a" onClick={(e) => WithdrawFormOpen(e, 'withdraw-popup-window')}>ถอนเงิน</button>
              </li>
              <li>
                <Link to="/freespin-terms-and-conditions/">เงื่อนไขฟรีสปิน</Link>
              </li>
              <li><LogoutButton inListing={true}/></li>
            </ul>
          </div>
        </div>
        <WithdrawForm popupId={'withdraw-popup-window'}/>
      </Wrapper> :
      <Wrapper className="auth-wrapper">
        <LoginRegisterButtons hideRegButton={hideRegButton}/>
        <LoginForm popupId={'login-popup-window'} />
      </Wrapper>
  );
};
export {getAuthToken, getAuthUserName, resetAuthToken, hasAuthToken, setAuthToken, activationUser, LoginFormOpen, Logout, LogoutButton};
export default Auth;

Auth.propTypes = {
  hideRegButton: PropTypes.bool
};
