import React from 'react';
import MainMenu from '../Menus/MainMenuNew';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../utils/style';

const AppHeader = styled('header')`
margin-top: 15px;  
&.on-front {
    position: relative;
    z-index: 10;
  }
  
  
  @media (min-width: ${breakpoints.mdMin}) {
    height: auto;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  @media (max-width: ${breakpoints.md}) {
    height: auto;
    position: sticky;
    top: 0;
    z-index: 999;
    margin-top:-5px;
    display: block;
    width: 100%;
    padding: 0;
    &.on-front {
      position: static;
    }
  }

`;


export default ({ location }) => (
  <AppHeader id="app-header" className='app-header' >
    <MainMenu isHome={location === '/'} />
  </AppHeader>
);