import React from 'react';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';


const MobileLineLink = () => {
  return (
    <a href={process.env.LINE_ID} aria-label="Line" target="_blank" rel="noopener noreferrer">
      <IcomoonIcon className="contact-menu-icon" icon="line-flat" color={color.red} size={30} />
    </a>
  );
};

export default MobileLineLink;