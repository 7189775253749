import React from 'react';
import Cookies from 'universal-cookie';
import {activationUrl} from './Config.js'

const cookies = new Cookies();

const setAuthToken = (token) => {
  return cookies.set('Auth-Token', token, {path:'/'});
};

const getAuthToken = () => {
  return cookies.get('Auth-Token', {path:'/'});
};

const getAuthHeader = () => {
  return 'Bearer ' + getAuthToken();
};

const resetAuthToken = () => {
  cookies.remove('Auth-Token', {path:'/'});
};

const setAuthUserName = (username) => {
  return cookies.set('Auth-Token-User-Name', username, {path:'/'});
};

const getAuthUserName = () => {
  return cookies.get('Auth-Token-User-Name', {path:'/'});
};

const resetAuthUserName = () => {
  cookies.remove('Auth-Token-User-Name', {path:'/'});
};


const hasAuthToken = () => {
  let token = getAuthToken();
  return (token && token.length > 0)
};

const Logout = (event, setUsernameCallback = false) => {
  setAuthUserName('');
  setAuthToken('');
  if(setUsernameCallback){
    setUsernameCallback(false);
  }
};

const activationUser = async (path, query, activationCallback, errorCallback) => {

  const response = await fetch(activationUrl, {
    method: 'POST',
    body: JSON.stringify(query),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    let json = await response.json();
    if (json.error && json.message) {
      console.log(json.message);
      console.log(json.error_type);
      errorCallback(json.error);
    } else {
      console.log('Player Activated');
      setAuthToken(json.token);
      setAuthUserName(json.user_nicename);
      activationCallback(true);
    }
  } else {
    console.log("Activation Error HTTP: " + response.status);
  }
};

export {setAuthToken, getAuthToken, resetAuthToken, hasAuthToken, Logout, activationUser, setAuthUserName, getAuthUserName, getAuthHeader};