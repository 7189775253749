import React from 'react';

const domain = process.env.API_PROTOCOL + '://' + process.env.API_DOMAIN;
const registerUrl = domain + '/wp-json/freespin/v1/register';
const activationUrl = domain + '/wp-json/freespin/v1/activation';
const newPasswordUrl = domain + '/wp-json/freespin/v1/newpassword';
const resetLinkUrl = domain + '/wp-json/freespin/v1/resetpassword';
const loginUrl = domain + '/wp-json/jwt-auth/v1/token';
const balanceUrl = domain + '/wp-json/freespin/v1/balance';
const getUserApiService = 'https://api.ipify.org?format=json';
const lineCabinetRegister = 'https://cabinet.club-play.net/registration';
const lineCabinetLogin = 'https://cabinet.club-play.net/login';
export {domain, registerUrl, activationUrl, loginUrl, balanceUrl,
  getUserApiService, lineCabinetRegister, lineCabinetLogin, newPasswordUrl, resetLinkUrl};
