import React from 'react';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const MobileContactLink = () => {
  return (
    <a aria-label="Chat" href={`${process.env.HEADER_ZOPIM_LINK}`} target="_blank" rel="noopener noreferrer">
      <IcomoonIcon className="contact-menu-icon" icon="chat-new" color={color.red} size={30} />
    </a>
  );
};

export default MobileContactLink;