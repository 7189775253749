import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, formStyles, sizing } from '../../../utils/style';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { loginUrl } from '../Config.js';
import { setAuthToken, setAuthUserName } from '../Utils';
import { closePopup } from '../../shared/PopupWindow';
import IcomoonIcon from '../../shared/IcomoonIcon';
import StoreContext from '../../shared/Context/StoreContext';

const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  padding: 0 0 20px;
  margin-top: -10px;
  text-align: left;
  .loading {
    position: relative;
  }
  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
  .two-fields {
    .fieldWrap {
      width: 48%;
      display: inline-block;
      vertical-align: top;
    }
  }
  .red-button {
    float: right;
  }
  .button {
    svg {
      margin-left: 5px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  .inputName {
    margin-bottom: 5px;
  }
  .forgot-password {
    font-size: ${sizing.small};
  }
`;

const MsgWrap = styled('div')`
  margin: 10px 0 30px;
  span {
    display: none;
    background-color: ${color.yellow};
    color: ${color.black};
    padding: 5px 10px;
    text-align: center;
    &.visible {
      display: block;
    }
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  .white-button {
    margin-right: 0;
  }
`;

let setUsernameCallback = false;
class FormBody extends Component {
  static contextType = StoreContext;

  showErrorMsg = msg => {
    const errorMsg = document.getElementById('loginErrorMsg');
    errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
  };

  onSubmit = async (values, formApi) => {
    const body = JSON.stringify(values);
    const response = await fetch(loginUrl, {
      method: "post",
      body: body,
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (response.ok) {
      let json = await response.json();
      if (json.error && json.error_type === "player_not_exists") {
        this.showErrorMsg("Player does not exist, create an account first!"); // Player not exists, register your account first!
        formApi.setSubmitting(false);
        return;
      }
      if (json.error && json.error_type === "player_not_activated") {
        this.showErrorMsg("กรุณาเช็คอีเมลของคุณเพื่อเปิดการใช้งานบัญชี"); // Please check your email and activate your account first!
        formApi.setSubmitting(false);
        return;
      }

      let token = json.token;
      if (token) {
        setAuthToken(token);
        setAuthUserName(values.username);
        setUsernameCallback(values.username);
        formApi.setSubmitting(false);
        if (this.props.popupId) {
          closePopup(false, "login-popup-window");
        }

      } else {
        this.showErrorMsg("Unknown error, try again."); // Unknown error, try again.
      }
    } else {
      let json = await response.json();
      this.showErrorMsg("ขออภัย ยูสเซอร์เนมและ/หรือรหัสผ่านไม่ถูกต้อ");
      formApi.setSubmitting(false);

      console.log("Login HTTP Error: " + response.status + ". Error: " + json.message);
    }
  };

  render() {
    const value = this.context;
    setUsernameCallback = value.setUsernameCallback;
    const initialValues = {
      username: '',
      password: '',
    };
    return (
      <Wrapper>
        <MsgWrap id="loginMsg">
          <span className="errorMsg" id="loginErrorMsg">
            Failed sending form. Please try again
          </span>
          <span className="successMsg" id="successSuccessMsg">
            Form successfully sent!
          </span>
        </MsgWrap>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validationSchema={Yup.object().shape({
            username: Yup.string().required("จำเป็นต้องระบุ"),
            password: Yup.string().required("จำเป็นต้องระบุ"),
          })}
        >
          {({ errors, status, touched, isSubmitting, isValid, values }) => (
            <Form id="loginForm">
              <div className="inputName">ยูสเซอร์เนม</div>
              <div className="fieldWrap iconField">
                <IcomoonIcon icon="user" color={color.white} size={18} />
                {errors.username && touched.username && (
                  <div className="error-new">! {errors.username}</div>
                )}
                <Field
                  type="text"
                  name="username"
                  placeholder="ยูสเซอร์เนม"
                  id="login-username"
                  value={values.username || ""}
                />
              </div>
              <div className="inputName">รหัสผ่าน</div>
              <div className="fieldWrap iconField">
                <IcomoonIcon icon="pass" color={color.white} size={20} />
                {errors.password && touched.password && (
                  <div className="error-new">! {errors.password}</div>
                )}
                <Field
                  type="password"
                  name="password"
                  placeholder="รหัสผ่าน"
                  id="login-password"
                  value={values.password || ""}
                  autoComplete="on"
                />
              </div>
              <BtnWrapper>
                <button
                  aria-label="Submit"
                  className="button red-button"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  <span>เข้าสู่ระบบ</span>
                </button>
                {status && status.msg && <div>{status.msg}</div>}
                <a
                  aria-label="Register"
                  className="button light-orange-button"
                  href="/register/?register_type=onsitegames"
                >
                  สมัครสมาชิก
                </a>
              </BtnWrapper>
              <a
                className="forgot-password"
                target="_blank"
                rel="noopener noreferrer"
                href={"/freshchat/"}
              >
                ลืมรหัสผ่านใช่ไหม? โปรดติดต่อคอลเซ็นเตอร์
              </a>
            </Form>
          )}
        </Formik>
      </Wrapper>
    )
  }
}

export default FormBody;

FormBody.propTypes = {
  popupId: PropTypes.string,
};
