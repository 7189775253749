import React from 'react';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import TopSearchIcon from '../../../icons/svg/TopSearch';

const El = styled('button')`
  display: none;
  width: 52px;
  height: 48px;
  background: ${color.transparentWhite1};
  border: none;
  border-radius: 24px;
  padding: 4px;
  padding-top: 0;
  &:active,
  &:focus {
    outline: none;
    opacity: 0.7;
  }
  
  #evt6iu257zf2_to {
    animation: evt6iu257zf2_to__to 7000ms linear infinite normal forwards;
    animation-delay: 7s;
  }

  #evt6iu257zf2_ts {
    animation-delay: 7s;
    animation: evt6iu257zf2_ts__ts 7000ms linear infinite normal forwards
  }

  #evt6iu257zf4 {
    animation-delay: 7s;
    animation-name: evt6iu257zf4_s_do, evt6iu257zf4_s_da;
    animation-duration: 7000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }

  #evt6iu257zf5_tr {
    animation-delay: 7s;
    animation: evt6iu257zf5_tr__tr 7000ms linear infinite normal forwards
  }

  #evt6iu257zf5 {
    animation-name: evt6iu257zf5_s_do, evt6iu257zf5_s_da;
    animation-delay: 7s;
    animation-duration: 7000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }

  #evt6iu257zf7 {
    animation-name: evt6iu257zf7__m, evt6iu257zf7_s_o, evt6iu257zf7_s_do, evt6iu257zf7_s_da;
    animation-duration: 7000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-delay: 7s;
  }

  #evt6iu257zf8 {
    animation-name: evt6iu257zf8_c_o, evt6iu257zf8_s_do, evt6iu257zf8_s_da;
    animation-duration: 7000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: 7s;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }

  @keyframes evt6iu257zf2_to__to {
    0% {
      transform: translate(6.262051px, 7.310052px)
    }
    37.142857% {
      transform: translate(6.262051px, 7.310052px)
    }
    44.285714% {
      transform: translate(4.814462px, 5.872062px)
    }
    64.285714% {
      transform: translate(4.814462px, 5.872062px)
    }
    70% {
      transform: translate(6.985846px, 8.029047px)
    }
    72.857143% {
      transform: translate(6.262051px, 7.310052px)
    }
    100% {
      transform: translate(6.262051px, 7.310052px)
    }
  }

  @keyframes evt6iu257zf2_ts__ts {
    0% {
      transform: scale(1, 1)
    }
    37.142857% {
      transform: scale(1, 1)
    }
    44.285714% {
      transform: scale(1.200000, 1.200000)
    }
    64.285714% {
      transform: scale(1.200000, 1.200000)
    }
    70% {
      transform: scale(0.900000, 0.900000)
    }
    72.857143% {
      transform: scale(1, 1)
    }
    100% {
      transform: scale(1, 1)
    }
  }

  @keyframes evt6iu257zf4_s_do {
    0% {
      stroke-dashoffset: 43.980000
    }
    42.857143% {
      stroke-dashoffset: 43.980000
    }
    64.285714% {
      stroke-dashoffset: 0
    }
    100% {
      stroke-dashoffset: 0
    }
  }

  @keyframes evt6iu257zf4_s_da {
    0% {
      stroke-dasharray: 43.980000
    }
    42.857143% {
      stroke-dasharray: 43.980000
    }
    64.285714% {
      stroke-dasharray: 43.980000
    }
    100% {
      stroke-dasharray: 43.980000
    }
  }

  @keyframes evt6iu257zf5_tr__tr {
    0% {
      transform: translate(7px, 7px) rotate(359.150000deg)
    }
    42.857143% {
      transform: translate(7px, 7px) rotate(359.150000deg)
    }
    62.857143% {
      transform: translate(7px, 7px) rotate(376.348944deg)
    }
    100% {
      transform: translate(7px, 7px) rotate(376.348944deg)
    }
  }

  @keyframes evt6iu257zf5_s_do {
    0% {
      stroke-dashoffset: 43.980000
    }
    42.857143% {
      stroke-dashoffset: 43.980000
    }
    62.857143% {
      stroke-dashoffset: 3
    }
    64.285714% {
      stroke-dashoffset: 0
    }
    100% {
        stroke-dashoffset: 0
    }
  }

  @keyframes evt6iu257zf5_s_da {
    0% {
      stroke-dasharray: 43.980000
    }
    42.857143% {
      stroke-dasharray: 43.980000
    }
    62.857143% {
      stroke-dasharray: 40
    }
    64.285714% {
      stroke-dasharray: 43.980000
    }
    100% {
      stroke-dasharray: 43.980000
    }
  }

  @keyframes evt6iu257zf7__m {
    0% {
      d: path('M17.353000,17.257000L13.500000,13.662000')
    }
    37.142857% {
      d: path('M17.353000,17.257000L13.500000,13.662000')
    }
    44.285714% {
      d: path('M14.084193,14.223666L13.500000,13.682000')
    }
    100% {
      d: path('M14.084193,14.223666L13.500000,13.682000')
    }
  }

  @keyframes evt6iu257zf7_s_o {
    0% {
      stroke-opacity: 1
    }
    37.142857% {
      stroke-opacity: 1
    }
    42.857143% {
      stroke-opacity: 1
    }
    44.285714% {
      stroke-opacity: 0
    }
    100% {
      stroke-opacity: 0
    }
  }

  @keyframes evt6iu257zf7_s_do {
    0% {
      stroke-dashoffset: 5.270000
    }
    37.142857% {
      stroke-dashoffset: 5.270000
    }
    44.285714% {
      stroke-dashoffset: 0
    }
    100% {
      stroke-dashoffset: 0
    }
  }

  @keyframes evt6iu257zf7_s_da {
    0% {
      stroke-dasharray: 5.270000
    }
    37.142857% {
      stroke-dasharray: 5.270000
    }
    40% {
      stroke-dasharray: 5.270000
    }
    44.285714% {
      stroke-dasharray: 5.270000
    }
    100% {
      stroke-dasharray: 5.270000
    }
  }

  @keyframes evt6iu257zf8_c_o {
    0% {
      opacity: 0
    }
    46.714286% {
      opacity: 0
    }
    52.857143% {
      opacity: 1
    }
    60% {
      opacity: 1
    }
    67.142857% {
      opacity: 0
    }
    100% {
      opacity: 0
    }
  }

  @keyframes evt6iu257zf8_s_do {
    0% {
      stroke-dashoffset: 172.790000
    }
    37.142857% {
      stroke-dashoffset: 172.790000
    }
    74.285714% {
      stroke-dashoffset: 172.790000
    }
    100% {
      stroke-dashoffset: 172.790000
    }
  }

  @keyframes evt6iu257zf8_s_da {
    0% {
      stroke-dasharray: 20
    }
    37.142857% {
      stroke-dasharray: 20
    }
    72.857143% {
      stroke-dasharray: 100
    }
    100% {
      stroke-dasharray: 100
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 13px;
    padding-top: 7px;
    padding-right: 3px;
  }
  @media (max-width: ${breakpoints.sm}) {
    padding-top: 7px;
    right: 20px;
  }
  @media (max-width: ${breakpoints.xs}) {
    right: 15px;
    top: 15px;
    width: 46px;
    height: 46px;
    padding: 5px 11px;
    svg {
      max-width: 100%;
    }
  }
`;

const TopSearch = () => {
  const handleClick = e => {
    e.preventDefault();
    const block = document.getElementById('header-contacts-menu');
    const layout = document.getElementById('all-content');
    const navWrap = document.getElementById('nav-wrap');
    layout.classList.toggle('inter-blocked');
    block.classList.toggle('open');
    navWrap.classList.toggle('search-open');
  };

  return (
    <El id="searchButton" aria-label="Search" onClick={e => handleClick(e)}>
      <TopSearchIcon/>
    </El>
  );
};

export default TopSearch;
