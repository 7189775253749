import React from 'react';
import PropTypes from 'prop-types';
import iconSet from '../../icons/selection.json';
import IcomoonReact from 'icomoon-react';

const IcomoonIcon = ({ color, icon, size, className }) => (
  <IcomoonReact iconSet={iconSet} color={color} size={size} icon={icon} />
);

IcomoonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default IcomoonIcon;