import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, formStyles, breakpoints } from '../../utils/style';
import { Formik, Field, Form } from 'formik';
import withLocation from '../shared/withLocation';
import TopSearchButton from '../../icons/svg/TopSearchButton';
const SearchWrap = styled('div')`
  ${formStyles}
  .search-form {
    margin-top: 2px;
    max-width: 194px;
    margin: 0 auto;
    position: relative;
    input {
      height: 48px;
      border-color: ${color.white};
    }
    .error-new + input {
      border-color: ${color.red};
    }
    .fieldWrap {
      width: 100%;
      .error {
        right: auto;
        top: 1px;
        left: 50px;
        background: none;
        font-size: 11px;
      }
      label {
        display: none;
      }
    }
    .button {
      opacity: 1;
      padding: 0 12px;
      margin: 0 0 0 10px;
      position: absolute;
      top: 3px;
      right: 3px;
      background: transparent;
      border-color: transparent;
      &:hover,
      &:active {
        background: ${color.orange};
        svg {
          path {
            fill: ${color.trueBlack} !important;
          }
        }
      }
      &:disabled {
        background: transparent;
        border-color: transparent;
      }
    }
  }
  
  #evt6iu257zf2_to1 {
    animation: evt6iu257zf2_to__to1 7000ms linear infinite normal forwards;
    animation-delay: 7s;
  }

  #evt6iu257zf2_ts1 {
    animation-delay: 7s;
    animation: evt6iu257zf2_ts__ts1 7000ms linear infinite normal forwards
  }

  #evt6iu257zf41 {
    animation-delay: 7s;
    animation-name: evt6iu257zf4_s_do1, evt6iu257zf4_s_da1;
    animation-duration: 7000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }

  #evt6iu257zf5_tr1 {
    animation-delay: 7s;
    animation: evt6iu257zf5_tr__tr1 7000ms linear infinite normal forwards
  }

  #evt6iu257zf51 {
    animation-name: evt6iu257zf5_s_do1, evt6iu257zf5_s_da1;
    animation-delay: 7s;
    animation-duration: 7000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }

  #evt6iu257zf71 {
    animation-name: evt6iu257zf7__m1, evt6iu257zf7_s_o1, evt6iu257zf7_s_do1, evt6iu257zf7_s_da1;
    animation-duration: 7000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-delay: 7s;
  }

  #evt6iu257zf81 {
    animation-name: evt6iu257zf8_c_o1, evt6iu257zf8_s_do1, evt6iu257zf8_s_da1;
    animation-duration: 7000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: 7s;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }

  @keyframes evt6iu257zf2_to__to1 {
    0% {
      transform: translate(6.262051px, 7.310052px)
    }
    37.142857% {
      transform: translate(6.262051px, 7.310052px)
    }
    44.285714% {
      transform: translate(4.814462px, 5.872062px)
    }
    64.285714% {
      transform: translate(4.814462px, 5.872062px)
    }
    70% {
      transform: translate(6.985846px, 8.029047px)
    }
    72.857143% {
      transform: translate(6.262051px, 7.310052px)
    }
    100% {
      transform: translate(6.262051px, 7.310052px)
    }
  }

  @keyframes evt6iu257zf2_ts__ts1 {
    0% {
      transform: scale(1, 1)
    }
    37.142857% {
      transform: scale(1, 1)
    }
    44.285714% {
      transform: scale(1.200000, 1.200000)
    }
    64.285714% {
      transform: scale(1.200000, 1.200000)
    }
    70% {
      transform: scale(0.900000, 0.900000)
    }
    72.857143% {
      transform: scale(1, 1)
    }
    100% {
      transform: scale(1, 1)
    }
  }

  @keyframes evt6iu257zf4_s_do1 {
    0% {
      stroke-dashoffset: 43.980000
    }
    42.857143% {
      stroke-dashoffset: 43.980000
    }
    64.285714% {
      stroke-dashoffset: 0
    }
    100% {
      stroke-dashoffset: 0
    }
  }

  @keyframes evt6iu257zf4_s_da1 {
    0% {
      stroke-dasharray: 43.980000
    }
    42.857143% {
      stroke-dasharray: 43.980000
    }
    64.285714% {
      stroke-dasharray: 43.980000
    }
    100% {
      stroke-dasharray: 43.980000
    }
  }

  @keyframes evt6iu257zf5_tr__tr1 {
    0% {
      transform: translate(7px, 7px) rotate(359.150000deg)
    }
    42.857143% {
      transform: translate(7px, 7px) rotate(359.150000deg)
    }
    62.857143% {
      transform: translate(7px, 7px) rotate(376.348944deg)
    }
    100% {
      transform: translate(7px, 7px) rotate(376.348944deg)
    }
  }

  @keyframes evt6iu257zf5_s_do1 {
    0% {
      stroke-dashoffset: 43.980000
    }
    42.857143% {
      stroke-dashoffset: 43.980000
    }
    62.857143% {
      stroke-dashoffset: 3
    }
    64.285714% {
      stroke-dashoffset: 0
    }
    100% {
        stroke-dashoffset: 0
    }
  }

  @keyframes evt6iu257zf5_s_da1 {
    0% {
      stroke-dasharray: 43.980000
    }
    42.857143% {
      stroke-dasharray: 43.980000
    }
    62.857143% {
      stroke-dasharray: 40
    }
    64.285714% {
      stroke-dasharray: 43.980000
    }
    100% {
      stroke-dasharray: 43.980000
    }
  }

  @keyframes evt6iu257zf7__m1 {
    0% {
      d: path('M17.353000,17.257000L13.500000,13.662000')
    }
    37.142857% {
      d: path('M17.353000,17.257000L13.500000,13.662000')
    }
    44.285714% {
      d: path('M14.084193,14.223666L13.500000,13.682000')
    }
    100% {
      d: path('M14.084193,14.223666L13.500000,13.682000')
    }
  }

  @keyframes evt6iu257zf7_s_o1 {
    0% {
      stroke-opacity: 1
    }
    37.142857% {
      stroke-opacity: 1
    }
    42.857143% {
      stroke-opacity: 1
    }
    44.285714% {
      stroke-opacity: 0
    }
    100% {
      stroke-opacity: 0
    }
  }

  @keyframes evt6iu257zf7_s_do1 {
    0% {
      stroke-dashoffset: 5.270000
    }
    37.142857% {
      stroke-dashoffset: 5.270000
    }
    44.285714% {
      stroke-dashoffset: 0
    }
    100% {
      stroke-dashoffset: 0
    }
  }

  @keyframes evt6iu257zf7_s_da1 {
    0% {
      stroke-dasharray: 5.270000
    }
    37.142857% {
      stroke-dasharray: 5.270000
    }
    40% {
      stroke-dasharray: 5.270000
    }
    44.285714% {
      stroke-dasharray: 5.270000
    }
    100% {
      stroke-dasharray: 5.270000
    }
  }

  @keyframes evt6iu257zf8_c_o1 {
    0% {
      opacity: 0
    }
    46.714286% {
      opacity: 0
    }
    52.857143% {
      opacity: 1
    }
    60% {
      opacity: 1
    }
    67.142857% {
      opacity: 0
    }
    100% {
      opacity: 0
    }
  }

  @keyframes evt6iu257zf8_s_do1 {
    0% {
      stroke-dashoffset: 172.790000
    }
    37.142857% {
      stroke-dashoffset: 172.790000
    }
    74.285714% {
      stroke-dashoffset: 172.790000
    }
    100% {
      stroke-dashoffset: 172.790000
    }
  }

  @keyframes evt6iu257zf8_s_da1 {
    0% {
      stroke-dasharray: 20
    }
    37.142857% {
      stroke-dasharray: 20
    }
    72.857143% {
      stroke-dasharray: 100
    }
    100% {
      stroke-dasharray: 100
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 15px;
    height: 75px;
    width: 100%;
    .search-form {
      width: calc(100% - 85px);
      float: left;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .search-form {
      margin-top: 0;
      .fieldWrap {
        padding-bottom: 3px;
      }
    }
  }
`;

const FormBody = ({ search, mobileCloseFunction }) => {
  let query = search;
  let searchtext = '';
  if (query && query.q) {
    searchtext = query.q;
  }
  const initialValues = {
    q: searchtext,
  };
  const onSubmit = (values, { resetForm }) => {
    const path = '/search/?q=' + values.q;
    navigate(path);
  };

  /*const onBlur = (e) => {
   const searchValue = e.target.value;
   if (query.q && (searchValue !== query.q)) {
     const path = '/search/?q=' + searchValue;
     mobileCloseFunction();
     navigate(path);
   }
  };*/

  const validateSearch = values => {
    let errors = {};
    let searchtext = values.q + '';
    if (searchtext.length < 3) {
      errors.q = 'อย่างน้อย 3 ตัวอักษร';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateSearch}
      render={({ errors, status, touched, isSubmitting, isValid, values }) => (
        <Form id="searchForm" className="search-form">
          <div className="fieldWrap">
            <label htmlFor="search-query">Search</label>
            {errors.q && touched.q && (
              <div className="error-new">! {errors.q}</div>
            )}
            <Field
              type="text"
              name="q"
              placeholder="ค้นหา"
              id="search-query"
              value={values.q || ''}
            />
          </div>
          <button
            aria-label="เดิน"
            className="button"
            type="submit"
            id="searchButton"
            disabled={isSubmitting || !isValid}
          >
            <TopSearchButton/>
          </button>
        </Form>
      )}
    />
  );
};

const SearchForm = ({ search, handlerFunction }) => {
  return (
    <SearchWrap>
      <FormBody search={search} mobileCloseFunction={handlerFunction} />
    </SearchWrap>
  );
};

export default withLocation(SearchForm);

SearchForm.propTypes = {
  search: PropTypes.object,
  handlerFunction: PropTypes.func,
};
